import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles<Theme>({
  container: {
    padding: '30px 80px',
    display: 'flex',
    flexDirection: 'column',
  },

  titleText: {
    fontWeight: 'bold',
    marginBottom: '16px',
  },

  cardsContainer: {
    padding: '24px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start' /* Выравнивание карточек по левому краю */,
    gap: '16px' /* Отступы между карточками */,
    borderRadius: '12px',
    border: '1px solid #EDEDED',
    backgroundColor: 'white',
    boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.05)',
  },
});
