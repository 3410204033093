import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles<Theme>({
  modalContainer: {
    background: 'rgba(0, 0, 0, 0.10)',
    backdropFilter: 'blur(25px)',
  },

  modalContentContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },

  modalLeftContainer: {
    width: 500,
    maxHeight: '70vh',
    marginRight: 32,
    padding: '24px',
    borderRadius: '24px',
    border: '1px solid #EDEDED',
    backgroundColor: 'white',
    overflowY: 'auto',
  },

  contentText: {
    color: '#212121',
    fontSize: '16px',
  },

  modalRightContainer: {
    padding: '24px',
    borderRadius: '24px',
    border: '1px solid #EDEDED',
    backgroundColor: 'white',
  },

  modalIconContainer: {
    position: 'absolute',
    right: '24px',
    top: '24px',
    display: 'flex',
    width: '64px',
    height: '64px',
    padding: '10px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    borderRadius: '12px',
    border: '1px solid #EDEDED',
    backgroundColor: 'white',
    boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.05)',
    '&:hover': {
      backgroundColor: 'rgba(255, 255,255, 0.8)', // Фон при наведении
    },
  },

  modalIcon: {
    color: 'black',
    width: '24px',
    height: '24px',
  },

  popupContainer: {
    borderRadius: '24px',
  },

  popupContentContainer: {
    position: 'relative',
    padding: '24px',
  },

  popupIconContainer: {
    position: 'absolute',
    top: 4,
    right: 4,
  },

  popupIcon: {
    color: 'black',
  },
});
