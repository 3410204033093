import { FilesData, Gender, Media, MediaType, MentalLevel, QuestionItem } from 'src/types/user';
import { ApiUserPreferences, GenderIdentityPreferences } from 'src/types/user/preferences';

export enum ActionType {
  ResetName = 'resetName',
  ResetAbout = 'resetAbout',
  ResetOccupation = 'resetOccupation',
  Scam = 'scam',
  Unscam = 'unscam',
  Trash = 'trash',
  AdultContent = 'eighteenPlus',
  NoUser = 'noUser',
  Approve = 'approve',
  SetGender = 'setGender',
  SetColor = 'setColor',
  Delete = 'delete',

  // uDates/Flure actions
  UnApprove = 'unapprove',
  SetPublicPhoto = 'setPublicPhoto',
  SetPrivatePhoto = 'setPrivatePhoto',
  Reject = 'reject',

  // Magnet actions
  ResetQuestions = 'resetQuestions',

  // Flure
  Benefits = 'benefits',
  ScamByMessage = 'scam-by-message',
}

export const ActionTypeMapper = {
  [ActionType.ResetName]: 'Reset name',
  [ActionType.ResetAbout]: 'Reset about',
  [ActionType.ResetOccupation]: 'Reset occupation',
  [ActionType.Scam]: 'Scam',
  [ActionType.Unscam]: 'Unscam',
  [ActionType.Trash]: 'Trash',
  [ActionType.AdultContent]: '18+',
  [ActionType.NoUser]: 'No user',
  [ActionType.Approve]: 'Approve',
  [ActionType.SetGender]: 'Set gender',
  [ActionType.SetColor]: 'Set color',
  [ActionType.Delete]: 'Delete',

  // uDates/Flure actions
  [ActionType.UnApprove]: 'Unapprove',
  [ActionType.SetPublicPhoto]: 'Set public photo',
  [ActionType.SetPrivatePhoto]: 'Set private photo',
  [ActionType.Reject]: 'Reject',

  // Magnet actions
  [ActionType.ResetQuestions]: 'Reset questions',

  // Flure
  [ActionType.Benefits]: 'Give benefits',
  [ActionType.ScamByMessage]: 'Scam by message',
};

export const ActionsRequiredConfirmation = [
  ActionType.AdultContent,
  ActionType.NoUser,
  ActionType.Reject,
  ActionType.Scam,
  ActionType.Trash,
];

export enum BenefitType {
  Subscription = 'subscription',
  Vibes = 'vibes',
}

export type BenefitsActionValue = {
  [BenefitType.Subscription]?: string;
  [BenefitType.Vibes]?: number;
};

export type ModerationAction = {
  actionType: ActionType;
  actionValue?: string;
};

export enum UserForModerationStatus {
  Ok = 200,
  NoUndoUsers = 404,
}

export type UserForModeration = {
  userId: string;
  contentId: number;
  newUserName?: string;
  newUserAbout?: string;
  newUserOccupation?: string;
  newMediaBaseName?: string;
  newMediaSource?: string;
  newMediaType?: MediaType;
  name: string;
  about: string;
  questions: QuestionItem[];
  occupation: string;
  gender: Gender;
  thumbnailMedia: Media;
  mainMediaSource: string;
  mainMediaType: MediaType;
  mentalLevel?: MentalLevel;
  userMedia: Media[];
  status: UserForModerationStatus;
  photoData?: FilesData;
  age: number;
};

export type UserForModerationFlure = {
  userId: string;
  contentId: number;
  newUserName?: string;
  newUserAbout?: string;
  newMediaBaseName?: string;
  newMediaSource?: string;
  newMediaType?: MediaType;
  name: string;
  about: string;
  gender: Gender;
  genderIdentity?: GenderIdentityPreferences;
  thumbnailMedia: Media;
  mainMediaSource: string;
  mainMediaType: MediaType;
  mentalLevel?: MentalLevel;
  userMedia: Media[];
  status: UserForModerationStatus;
  age: number;
  preferences?: ApiUserPreferences;
  city?: string;
  country?: string;
  height?: number;
  interests?: string[];
};
