import React from 'react';
import clsx from 'clsx';

import { useStyles } from './styles';

type Props = {
  className?: string;
  children: React.ReactElement;
};

export const Bubble = React.memo(({ children, className }: Props) => {
  const classes = useStyles();

  return <div className={clsx(classes.container, className)}>{children}</div>;
});
