import React, { useCallback } from 'react';
import { Box, Typography } from '@material-ui/core';
import moment from 'moment';

import { ActionType } from 'src/types/moderation';
import { Gender, MentalLevel } from 'src/types/user';

import { useStyles } from '../styles';
import { useDataContext } from '../hooks/useContextHooks';

const formatHistoryActionType = (actionType: string, actionValue: string) => {
  let moderationText;

  switch (actionType) {
    case ActionType.ResetName:
      moderationText = `Name changed to ${actionValue}`;
      break;
    case ActionType.ResetAbout:
      moderationText = `Bio changed to ${actionValue}`;
      break;
    case ActionType.Scam:
      moderationText = 'Scam';
      break;
    case ActionType.Trash:
      moderationText = 'Trash';
      break;
    case ActionType.AdultContent:
      moderationText = '18+';
      break;
    case ActionType.NoUser:
      moderationText = 'No user';
      break;
    case ActionType.Approve:
      moderationText = 'Approve';
      break;
    case ActionType.SetGender:
      moderationText = `Gender changed to ${actionValue === Gender.Male ? 'Man' : 'Woman'}`;
      break;
    case ActionType.SetColor:
      moderationText = `MentalLevel changed to ${actionValue === MentalLevel.Purple ? 'Purple' : 'Orange'}`;
      break;
    case ActionType.ScamByMessage:
      moderationText = 'Scam by message';
      break;
    case 'approvePhoto':
      moderationText = 'Photo Approved';
      break;
    // case 'setPrivatePhoto':
    //   moderationText = 'Photo has been set to private';
    // break;
    default:
      moderationText = actionType;
      break;
  }

  return moderationText;
};

export const UserModerationHistory = () => {
  const classes = useStyles();
  const { moderationHistory } = useDataContext();
  const actions = moderationHistory?.value?.actions;
  const formatHistoryText = useCallback(
    (createdAt: string, initiatorName: string, actionType: string, actionValue: string) => {
      const moderationText = formatHistoryActionType(actionType, actionValue);
      const statusIcon = moderationText?.toLowerCase()?.includes('approve') ? '🟢' : '🔴';

      return (
        <>
          {statusIcon}&nbsp;&nbsp;{moment(createdAt).format('MMM DD, YYYY • HH:mm')}&nbsp;&nbsp;&nbsp;&nbsp;
          {moderationText} by {initiatorName}
        </>
      );
    },
    [],
  );

  if (!actions?.length) {
    return null;
  }

  return (
    <Box className={classes.sectionContainer}>
      <Typography className={classes.sectionTitle}>History</Typography>
      {actions.map(({ createdAt, initiator, actionType, actionValue }) => (
        <Typography key={createdAt} className={classes.sectionText}>
          {formatHistoryText(createdAt, initiator.name, actionType, actionValue)}
        </Typography>
      ))}
    </Box>
  );
};
