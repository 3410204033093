import { useEffect } from 'react';
import { useAsyncFn } from 'react-use';

import { agencyRequest } from 'src/network/agency';
import { getRealm } from 'src/utils/realm';
import { useAuth } from 'src/services/auth';
import { useSnackbar } from 'src/utils/snackbar';
import { Agency } from 'src/types/agency';

export const useAgencies = () => {
  const { showErrorMessage } = useSnackbar();
  const { me } = useAuth();
  const realm = getRealm(me?.realm);

  const [agenciesState, fetchAgencies] = useAsyncFn(
    () =>
      agencyRequest.getAll(realm).catch((err) => {
        showErrorMessage('Failed to load agencies: ' + (err.message || 'Bad request'));

        return [] as Agency[];
      }),
    [showErrorMessage, realm],
    {
      loading: true,
      value: [],
    },
  );

  useEffect(() => {
    fetchAgencies();
  }, [fetchAgencies]);

  return {
    agencies: agenciesState.value!,
    loading: agenciesState.loading,
    fetchAgencies,
  };
};
