import { useCallback, useState } from 'react';

import { storage } from 'src/utils/storage';
import { authRequest } from 'src/network';
import { Http } from 'src/network/http';
import { UserRoles } from 'src/types/user/roles';
import { AuthUser, LoginFormFields } from 'src/types/auth';
import { getServerConfig } from 'src/config';

const availableRoles: UserRoles[] = [
  UserRoles.Agency,
  UserRoles.AgencyAdministrator,
  UserRoles.UsersOperator,
  UserRoles.Supervisor,
  UserRoles.CustomerSupport,
  UserRoles.ChatOperator,
  UserRoles.PromoCodesOperator,
  UserRoles.SearchOperator,
];

export const useAuthActions = () => {
  const [loading, setLoading] = useState(true);
  const [me, setMe] = useState<AuthUser | null>(null);

  const bootstrap = useCallback(async () => {
    const storedMe = storage.get<AuthUser | null>('me');

    if (storedMe) {
      const serverConfig = await getServerConfig();
      await Promise.all([Http.shared().setHost(serverConfig, storedMe.realm), Http.shared().setToken(storedMe.token)]);

      setMe({ ...storedMe, details: 'login:token' });
      storage.set('me', storedMe);
    }

    setLoading(false);
  }, []);

  const login = useCallback(async ({ email, password, realm }: LoginFormFields) => {
    try {
      const serverConfig = await getServerConfig();
      await Http.shared().setHost(serverConfig, realm);

      const response = await authRequest.login({ email, password });

      const userRoles = response.roles;
      if (!availableRoles.find((availableRole) => userRoles.includes(availableRole))) {
        return { success: false };
      }

      if (response) {
        setMe({ ...response, realm, details: 'login:password' });
        await storage.set('me', { ...response, realm });
        await Http.shared().setToken(response.token);
      }

      return { success: true };
    } catch (error) {
      return { success: false };
    }
  }, []);

  const logout = useCallback(async () => {
    await Promise.all([Http.shared().unsetHost(), Http.shared().unsetToken()]);

    setMe(null);
    storage.remove('me');

    return { success: true };
  }, []);

  return {
    loading,
    me,
    bootstrap,
    login,
    logout,
  };
};
