import React from 'react';
import { Dialog, DialogContent, IconButton, Popover } from '@material-ui/core';
import { CloseIcon } from '@material-ui/data-grid';
import Typography from '@material-ui/core/Typography';

import { ModerationContentCategories } from 'src/components/Moderation/screens/UserModeration/once/components/moderation-content-categories';

import { useStyles } from './styles';

type Props = {
  handleClose: () => void;
  isDialogOpen: boolean;
  content: string;
  selectedCategories: string[];
  anchorEl: Element | null;
};

export const StopWordDescriptionDialog = (props: Props) => {
  const { content, handleClose, anchorEl, isDialogOpen, selectedCategories } = props;

  const classes = useStyles();

  const isPopupOpen = Boolean(anchorEl);
  const id = isPopupOpen ? 'simple-popover' : undefined;

  return (
    <>
      <Dialog fullScreen open={isDialogOpen} onClose={handleClose} PaperProps={{ className: classes.modalContainer }}>
        <DialogContent
          className={classes.modalContentContainer}
          onClick={(event) => {
            if (event.target === event.currentTarget) {
              handleClose();
            }
          }}
        >
          <div className={classes.modalLeftContainer}>
            <Typography className={classes.contentText}>{content}</Typography>
          </div>
          <div className={classes.modalRightContainer}>
            <ModerationContentCategories selectedCategories={selectedCategories} />
          </div>

          <IconButton aria-label="close" onClick={handleClose} className={classes.modalIconContainer}>
            <CloseIcon className={classes.modalIcon} />
          </IconButton>
        </DialogContent>
      </Dialog>

      <Popover
        id={id}
        open={isPopupOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
        transformOrigin={{ vertical: 'center', horizontal: 'left' }}
        PaperProps={{ className: classes.popupContainer }}
      >
        <div className={classes.popupContentContainer}>
          <ModerationContentCategories selectedCategories={selectedCategories} />
          <IconButton aria-label="close" onClick={handleClose} className={classes.popupIconContainer}>
            <CloseIcon className={classes.popupIcon} />
          </IconButton>
        </div>
      </Popover>
    </>
  );
};
