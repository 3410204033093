import React, { useEffect, useRef, useState } from 'react';

import { Typography } from '@material-ui/core';

import { useStyles, textMaxLines, textLineHeight } from './styles';
import overflowingIcon from './overflowing.svg';
import { StopWordDescriptionDialog } from './description-dialog';

type Props = {
  content: string;
  categories?: string[];
};

export const OnceModerationContentCard = (props: Props) => {
  const { content, categories = [] } = props;

  const classes = useStyles();

  const textRef = useRef<HTMLSpanElement>(null);

  const [isOverflowing, setIsOverflowing] = useState(false);

  const [isDialogOpen, setOpen] = useState(false); // Состояние для отображения попапа
  const [anchorEl, setAnchorEl] = useState<Element | null>(null); // Хранит элемент, на который мы нажали

  const handleCardClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (isOverflowing) {
      setOpen(true);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  useEffect(() => {
    if (!textRef.current) return;

    const elementHeight = textRef.current.scrollHeight;
    const maxAllowedHeight = textMaxLines * textLineHeight;

    setIsOverflowing(elementHeight > maxAllowedHeight);
  }, [content]);

  return (
    <>
      <button type="button" className={classes.container} onClick={handleCardClick}>
        <Typography ref={textRef} className={classes.text}>
          {content}
        </Typography>

        {isOverflowing && (
          <div className={classes.overflowingContainer}>
            <img src={overflowingIcon} alt="overflowing ID" />
          </div>
        )}
      </button>

      <StopWordDescriptionDialog
        content={content}
        handleClose={handleClose}
        isDialogOpen={isDialogOpen}
        anchorEl={anchorEl}
        selectedCategories={categories}
      />
    </>
  );
};
