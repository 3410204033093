import { FormType, FormAction } from '../../types';

const makeList = <T extends string>(
  items: Record<string, T>,
  formatName?: (name: string, value: T) => string,
): ItemProps<T>[] =>
  Object.entries(items as Record<string, string>).map(([name, value]) => ({
    name: formatName?.(name, value as T) ?? name,
    value: value as T,
  }));

type ItemProps<T> = { name: string; value: T };

const actionsTitlesMap: Record<FormAction, string> = {
  [FormAction.Create]: 'Create',
  [FormAction.ChangeCommission]: 'Change commission',
};

export const typesList = makeList(FormType);
export const actionsList = makeList(FormAction, (_, action) => actionsTitlesMap[action]);
