import React from 'react';
import { useSnackbar } from 'notistack';
import copy from 'copy-to-clipboard';
import { CircularProgress } from '@material-ui/core';

import { Resources } from 'src/resources';
import { LabelText, LabelTextSize } from 'src/components/common/flure';
import { VerificationPhotoHistoryItem } from 'src/network/verification/types';
import { useGetVerificationPhotoAuthed } from 'src/components/Moderation/utils/image-source';

import { Bubble } from '../bubble';
import { useStyles } from './styles';

type Props = {
  item: VerificationPhotoHistoryItem;
};

const emptyObject = {};

export const VerificationPhoto = React.memo(({ item }: Props) => {
  const classes = useStyles();
  const { aiDecision, basePath, baseName, mediaSource } = item || emptyObject;
  const userId = basePath?.split('/')[2] || '';
  const getVerificationPhoto = useGetVerificationPhotoAuthed();
  const verificationPhotoSrc = basePath && baseName ? getVerificationPhoto(basePath, baseName) : '';

  const { enqueueSnackbar } = useSnackbar();

  const copyUserId = () => {
    copy(userId);
    enqueueSnackbar(Resources.strings.moderation.userIdCopiedAlert, { variant: 'success' });
  };

  return (
    <div className={classes.container}>
      <CircularProgress className={classes.loader} />
      {verificationPhotoSrc && <img src={verificationPhotoSrc} alt={baseName} className={classes.verificationImage} />}
      <Bubble className={classes.aiBubble}>
        <LabelText
          className={classes.whiteText}
          text={`${aiDecision?.isRecognized ? '🟢' : '🔴'} AI`}
          size={LabelTextSize.ExtraSmall}
        />
      </Bubble>
      <div className={classes.bottomBubbles}>
        {userId && (
          <Bubble>
            <button type="button" className={classes.button} onClick={copyUserId}>
              <LabelText
                className={classes.whiteTextUnderlined}
                text={`ID ${userId}`}
                size={LabelTextSize.ExtraSmall}
              />
            </button>
          </Bubble>
        )}
        {mediaSource && (
          <Bubble>
            <LabelText className={classes.whiteTextUnderlined} text={mediaSource} size={LabelTextSize.ExtraSmall} />
          </Bubble>
        )}
      </div>
    </div>
  );
});
