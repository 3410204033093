import React from 'react';

import { FormAction, FormType } from '../../types';

import { CreateOperatorForm } from './forms/create-operator';
import { ChangeOperatorCommissionForm } from './forms/change-operator-commission';
import { CreateAgencyForm } from './forms/create-agency';
import { ChangeAgencyCommissionForm } from './forms/change-agency-commission';

type Props = {
  type: FormType;
  action: FormAction;
};

type ManagingType = `${FormType}_${FormAction}`;

const format = (type: FormType, action: FormAction): ManagingType => `${type}_${action}`;

export const ManagingForm = (props: Props) => {
  const { type, action } = props;

  switch (format(type, action)) {
    case format(FormType.Operator, FormAction.Create):
      return <CreateOperatorForm />;

    case format(FormType.Agency, FormAction.Create):
      return <CreateAgencyForm />;

    case format(FormType.Operator, FormAction.ChangeCommission):
      return <ChangeOperatorCommissionForm />;

    case format(FormType.Agency, FormAction.ChangeCommission):
      return <ChangeAgencyCommissionForm />;

    default:
      return null;
  }
};
