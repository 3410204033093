import React, { forwardRef } from 'react';

import { Typography } from '@material-ui/core';

import { ONCE } from 'src/types/once';

import { useStyles } from './styles';
import { OnceModerationContentCard } from './card';

type Props = {
  contentDecision: ONCE.ContentDecision[];
};

export const OnceModerationContent = forwardRef<HTMLDivElement, Props>(({ contentDecision }, ref) => {
  const classes = useStyles();

  if (!contentDecision.length) return null;

  return (
    <div ref={ref} className={classes.container}>
      <Typography component="h1" variant="h5" className={classes.titleText}>
        🚫 Deleted
      </Typography>

      <div className={classes.cardsContainer}>
        {contentDecision.map(({ content, categories }, i) => {
          return <OnceModerationContentCard content={content} categories={categories} key={String(i)} />;
        })}
      </div>
    </div>
  );
});
