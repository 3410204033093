import { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { userRequest } from 'src/network';
import { RiskModerationRoutes } from 'src/components/RiskModeration/constants';

import { useDataContext } from './useContextHooks';
import { useActionErrorHandler } from './useActionErrorHandler';
import { useModerationDetails } from './useModerationDetails';
import { useAnnalsEventLog } from './useAnnalsEventLog';

const USER_DELETED_ROLE = 'suspended';

export const useDeleteAction = () => {
  const { userId, operatorRoles } = useModerationDetails();
  const { userRoles } = useDataContext();
  const [isLoading, setIsLoading] = useState(false);
  const deleted = useMemo(() => !!userRoles?.includes(USER_DELETED_ROLE), [userRoles]);
  const errorHandler = useActionErrorHandler();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const logEvent = useAnnalsEventLog();

  const deleteUser = useCallback(() => {
    if (deleted) return;

    setIsLoading(true);
    userRequest
      .deleteUser(userId)
      .then(() => {
        logEvent({
          action: 'deleted',
        });
        history.push(RiskModerationRoutes.Main);
        enqueueSnackbar('User successfully deleted', { variant: 'success' });
      })
      .catch((error) =>
        errorHandler('Delete', {
          error,
        }),
      )
      .finally(() => setIsLoading(false));
  }, [deleted, enqueueSnackbar, errorHandler, history, logEvent, userId]);

  return {
    available: operatorRoles.isProfileOrAdmin,
    deleted,
    isLoading,
    deleteUser,
  };
};
