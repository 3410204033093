import { OperatorBonusCommission } from 'src/types/operator';

export const validStarterBonusCommissions: OperatorBonusCommission[] = [
  0.01,
  0.02,
  0.03,
  0.04,
  0.05,
  0.06,
  0.07,
  0.08,
  0.09,
  0.1,
];

export const defaultStarterBonusCommission = validStarterBonusCommissions[0];

export const validFollowBonusCommissions: OperatorBonusCommission[] = [0.01, 0.02, 0.03, 0.04, 0.05];

export const defaultFollowBonusCommission: OperatorBonusCommission = 0.05;
