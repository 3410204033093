import { object, string, mixed, bool, SchemaOf } from 'yup';

import { OperatorBonusCommission, OperatorCities, OperatorCommission } from 'src/types/operator';
import { defaultCommission, operatorCities, validCommissions } from 'src/services/operator/config';
import { CreateOperatorPayload } from 'src/services/operator/hooks/use-create-operator';
import {
  defaultFollowBonusCommission,
  defaultStarterBonusCommission,
  validFollowBonusCommissions,
  validStarterBonusCommissions,
} from './config';

export type CreateOperatorForm = Omit<
  CreateOperatorPayload,
  'isFreeMember' | 'freeMemberAnimatorClientId' | 'animatorCity' | 'animatorCountry' | 'agencyTechName'
>;

export const defaultValues: CreateOperatorForm = {
  firstName: '',
  lastName: '',
  commission: defaultCommission,
  starterBonusCommission: defaultStarterBonusCommission,
  followBonusCommission: defaultFollowBonusCommission,
  isReadyForExplicit: false,
  city: operatorCities[0],
};

export const validationSchema: SchemaOf<CreateOperatorForm> = object().shape({
  firstName: string().default(defaultValues.firstName).required('Operator first name is required'),
  lastName: string().default(defaultValues.lastName).required('Operator last name is required'),
  commission: mixed<OperatorCommission>()
    .default(defaultValues.commission)
    .oneOf(validCommissions)
    .required('Commission is required'),
  starterBonusCommission: mixed<OperatorBonusCommission>()
    .default(defaultValues.starterBonusCommission!)
    .oneOf(validStarterBonusCommissions)
    .required('Starter bonus commission is required'),
  followBonusCommission: mixed<OperatorBonusCommission>()
    .default(defaultValues.followBonusCommission!)
    .oneOf(validFollowBonusCommissions)
    .required('Follow bonus commission is required'),
  isReadyForExplicit: bool().default(defaultValues.isReadyForExplicit).required('isReadyForExplicit is required'),
  city: mixed<OperatorCities>().default(defaultValues.city).oneOf(operatorCities).required('City is required'),
});
