import { useCallback, useEffect } from 'react';
import { useAsyncFn } from 'react-use';

import { operatorRequest } from 'src/network';
import { Operator, UpdatedOperatorState } from 'src/types/operator';
import { getRealm } from 'src/utils/realm';
import { useAuth } from 'src/services/auth';
import { useSnackbar } from 'src/utils/snackbar';

export const useOperators = () => {
  const { me } = useAuth();
  const realm = getRealm(me?.realm);
  const { showErrorMessage } = useSnackbar();

  const [operatorsState, fetchOperators] = useAsyncFn(
    () =>
      operatorRequest.getAll(realm).catch((err) => {
        showErrorMessage('Failed to load operators: ' + (err.message || 'Bad request'));

        return [] as Operator[];
      }),
    [showErrorMessage, realm],
    {
      loading: true,
      value: [],
    },
  );

  const updateOperator = useCallback(
    async (operatorId: string, data: UpdatedOperatorState) => {
      const preparedData = {
        ...data,
        agencyTechName: !data.agencyTechName || data.agencyTechName === 'null' ? null : data.agencyTechName,
        scheduleType: !data.scheduleType || data.scheduleType === 'null' ? null : data.scheduleType,
      };

      await operatorRequest.update(operatorId, { ...preparedData, realm });

      await fetchOperators();
    },
    [fetchOperators, realm],
  );

  useEffect(() => {
    fetchOperators();
  }, [fetchOperators]);

  return { operators: operatorsState.value!, loading: operatorsState.loading, updateOperator, fetchOperators };
};
