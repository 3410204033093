import React from 'react';
import Typography from '@material-ui/core/Typography';

import { Layout } from 'src/components/App/views/Layout';

import { OperatorCreationForm } from './CreationForm';

/**
 * This screen is used for all products except Once
 */
export const OperatorsManagingScreen = () => {
  return (
    <Layout>
      <Typography component="h1" variant="h5">
        Operators Managing
      </Typography>
      <OperatorCreationForm />
    </Layout>
  );
};
