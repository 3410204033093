import { Http } from 'src/network/http';

const logRiskManagmentEvents = (userId: string, body: Record<string, unknown>) => {
  return Http.shared().instance.post(`/annals/${userId}/risk-managment`, body, {
    headers: {
      'content-type': 'application/json',
      accept: 'application/json',
    },
  });
};

export const annalsRequest = {
  logRiskManagmentEvents,
};
