import { ApiUserPreferences, GenderIdentityPreferences } from './preferences';

export type Media = {
  baseName: string;
  mediaType: string;
  tags: MediaTag[];
};

export enum Gender {
  Male = 'mal',
  Female = 'fem',
}

export enum MediaState {
  Public = 'public',
  Private = 'private',
}

export type UserDto = {
  id: string;
  name: string;
  about: string;
  occupation: string;
  media: Media[];
  gender: Gender;
  thumbnail?: string;
  mentalLevel?: MentalLevel;
  mentalAge?: number;
  tags?: string[];
  questions?: Array<QuestionItem>;
  birthday: string;
  interests?: string[];
  city?: string;
  country?: string;
  preferences?: ApiUserPreferences;
  height?: number;
};

export type User = {
  id: string;
  name: string;
  about: string;
  tags?: string[];
  media?: Media[];
  thumbnail?: string;
  thumbnailType: MediaType;
  thumbnailSource: string;
  mentalAge?: number;
  gender?: Gender;
  lookingFor?: string; // for Magnet
  traits?: string[]; // for Magnet
  turnOns?: string[]; // for Magnet
  photoData?: FilesData; // for uDates
  questions?: Array<QuestionItem>;
  occupation?: string;
  birthday?: string;
  interests?: string[];
  city?: string;
  country?: string;
  age?: number;
  height?: number; // for Flure
  preferences?: ApiUserPreferences; // for Flure
  genderIdentity?: GenderIdentityPreferences; // for Flure
};

export enum MediaTag {
  Thumbnail = 'thumbnail',
  ThumbnailBody = 'thumbnail-body',
  Hidden = 'hidden',
  Verification = 'verification',
  Chat = 'chat',
  Spicy = 'spicy',
}

export enum MediaType {
  Video = 'video',
  Photo = 'photo',
}

export enum MentalLevel {
  Orange = 'orange',
  Purple = 'purple',
}

export enum UserTagType {
  Hidden = 'hidden',
  Boost = 'boost',
  EmailConfirmed = 'email.confirmed',
  Scam = 'scam',
  Subscription = 'subscription',
  Unapproved = 'unapproved',
  Membership = 'membership',
}

export type File = {
  basename: string;
  mediatype: string;
  created: string;
  tags: MediaTag[];
};

export type FilesData = {
  files: File[];
};

export type QuestionItem = { question: string; answer: string };

export type UserLocation = {
  latitude?: string | number;
  longitude?: string | number;
  city?: string;
  country?: string;
};

export type ActualSubscription = {
  sku: string;
  startDate: string;
  endDate: string;
  cancelDate?: string;
  externalSystem: string;
  isTest: boolean;
  isTrial: boolean;
};
