import { makeStyles, Theme } from '@material-ui/core';
import { Product } from 'src/types/product';

export const useStyles = makeStyles<
  Theme,
  {
    isOnceProduct: boolean;
    isMan: boolean;
    isPurple: boolean;
    realm?: Product;
  }
>({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '100%',
  },

  container: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexGrow: 1,
    margin: '3vh 0',
  },

  leftContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    height: '100%',
    paddingRight: '10%',
  },

  rightContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '50%',
    height: '100%',
  },

  buttonsContainer: {
    display: 'flex',
    marginLeft: '-6px',
    marginBottom: '28px',
  },

  flexContainer: {
    display: 'flex',
  },

  fieldContainer: {
    marginBottom: '5vh',
  },

  fieldWithoutButton: {
    marginBottom: '8px',
  },

  nameWithPhotoContainer: {
    display: 'flex',
  },

  name: {
    fontWeight: 'bold',
    fontSize: '40px',
    color: ({ realm }) => (realm === Product.Magnet ? '#6C20B9' : '#020D3D'),
    marginBottom: '16px',
  },

  bio: {
    marginBottom: '16px',
    fontSize: '18px',
    lineHeight: '21px',
    color: '#020D3D',
    opacity: 0.5,
    overflowY: 'auto',
    height: '20vh',
  },

  bottomContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },

  moderationButton: {
    color: 'white',
    textTransform: 'none',
    backgroundColor: '#40A3FF',
    borderRadius: '8px',
    marginInline: '6px',

    height: '45px',
    fontSize: '20px',
  },

  genderButton: {
    backgroundColor: (props) => (props.isMan ? '#40A3FF' : '#2DE440'),
    '&:hover': {
      backgroundColor: (props) => (props.isMan ? '#40A3FF' : '#2DE440'),
      opacity: 0.8,
    },
  },

  levelButton: {
    backgroundColor: (props) => (props.isPurple ? '#883FFF' : '#ED654C'),
    '&:hover': {
      backgroundColor: (props) => (props.isPurple ? '#883FFF' : '#ED654C'),
      opacity: 0.8,
    },
  },

  scamButton: {
    backgroundColor: '#ED654C',
    '&:hover': {
      backgroundColor: '#ED654C',
      opacity: 0.8,
    },
    '&.Mui-disabled': {
      backgroundColor: (props) => (props.isOnceProduct ? '#ED654C' : undefined),
      color: (props) => (props.isOnceProduct ? '#FFFFFF' : undefined),
    },
  },

  label: {
    color: 'white',
    fontSize: '20px',
    fontWeight: 'bold',
    textTransform: 'none',
    borderRadius: '8px',
    marginInline: '6px',
    boxShadow:
      '0px 24px 32px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',

    height: '45px',
    width: '108px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  blueBackground: {
    backgroundColor: '#40A3FF',
  },

  greenBackground: {
    backgroundColor: '#2DE440',
  },

  orangeBackground: {
    backgroundColor: '#ED654C',
  },

  purpleBackground: {
    backgroundColor: '#883FFF',
  },

  inactiveButton: {
    '&:hover': {
      opacity: 1,
      cursor: 'default',
    },
  },

  nextButton: {
    width: '246px',
  },

  moderationHistoryContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  userDeletedText: {
    fontWeight: 'bold',
    fontSize: '40px',
    color: '#020D3D',
    opacity: 0.5,
    textAlign: 'center',
    paddingTop: '50px',
    paddingBottom: '50px',
  },

  placeholderContainer: {
    height: 'calc(100vh - 84px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  placeholderText: {
    fontWeight: 'bold',
    fontSize: '40px',
    color: '#020D3D',
    opacity: 0.5,
    textAlign: 'center',
  },

  historyContainer: {
    width: '100%',
    borderTopWidth: '7px',
    borderTopColor: ({ realm }) => (realm === Product.Once || realm === Product.Flure ? '#F31D34' : '#883FFF'),
    borderTopStyle: 'solid',
    paddingTop: '32px',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '16px',
  },

  historyItem: {
    fontWeight: ({ realm }) => (realm === Product.Magnet ? 600 : 400),
    fontSize: '18px',
    lineHeight: ({ realm }) => (realm === Product.Magnet ? '36px' : '21px'),
    color: ({ realm }) => (realm === Product.Magnet ? '#6C20B9' : '#020D3D'),
    marginBottom: '12px',
  },

  footerContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexShrink: 0,
  },

  historyTitle: {
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '36px',
  },

  moderationContentContainer: {
    marginTop: '-8px',
    marginBottom: '8px',
  },
});
